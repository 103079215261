export default class SVGRuler {

    constructor (value, selection) {
        this.value = value
        this.selection = selection
    }

    correct (pos) {
        return pos
    }

}